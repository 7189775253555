import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image";


const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`
/* Text Group */
const CellTitleGroup = styled.div`
    width: 100%;
    @media (max-width: 640px){
        ${'' /* grid-row: 2; */}
        width: 100%;
        margin-top: 8px;
    }

`
const Title = styled.div`
    color: white;
    font-size: ${fontSize[3]};
    font-weight: 200;
    margin-top: ${space[4]+ "px"};
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    text-rendering: optimizeLegibility;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[2]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
const Subhead = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: white;
    margin-bottom: 0px;
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
/* Image styles */

const ImgSingle = styled(Img)`
    border-radius: 24px;
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    border: 1px solid ${color.gray6};
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <LayoutChild>
    <SEO 
      title="Scroll Reader App"
      description="A companion app to the Scroll experience"
      image="../images/card.jpg"
    />
    <PageLayout>
        <CellTitleGroup>
            <Title >sd-wan interface for&nbsp;Simplifi</Title>
            <Subhead>ROLE: designer</Subhead>
            <Text>Simplifi had a business need to provide complex policy and rule based filtering for its wireless router, but they were unclear how to efficiently create a front-end for such a complex set of requirements.<br/>After reviewing the product strategy and several rounds of brainstorming and ideation, it was clear the best solution lie in a set of modular components that could be combined to create the myriad of policies, filters, and rules.<br/>This strategy allowed Simplifi to quickly execute the feature while delivering a clear, consistent application interface to its&nbsp;customers.</Text>
            <Subhead>design strategy, concept, system, and user&nbsp;interface.</Subhead>
            </CellTitleGroup>
          <ImgDouble 
                fluid={data.image1.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image2.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image3.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image4.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image5.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image6.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image7.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image8.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image9.childImageSharp.fluid}
                alt="An image of a web routing application"
                fadeIn={true}
                 />                                            
        </PageLayout>
  </LayoutChild>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "sd-wan/sd-wan-1.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "sd-wan/sd-wan-2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image3: file(relativePath: { eq: "sd-wan/sd-wan-3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image4: file(relativePath: { eq: "sd-wan/sd-wan-4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image5: file(relativePath: { eq: "sd-wan/sd-wan-5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image6: file(relativePath: { eq: "sd-wan/sd-wan-6.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image7: file(relativePath: { eq: "sd-wan/sd-wan-7.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image8: file(relativePath: { eq: "sd-wan/sd-wan-8.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image9: file(relativePath: { eq: "sd-wan/sd-wan-9.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`