import { Link } from "gatsby"
import React from "react"
import './header.css'
import BtnHeader from "./Button-Header"

class HeaderBack extends React.Component{

 render() {
   return (
    <div className={'HeaderBlock HeaderScrolled'}>
      <div className={'HeaderNameBlockBack'}>
        <Link to="/">
            <div className="NameTitleBack">← Back</div>
        </Link>
        </div>
      <div className="HeaderButtonBlock">
        <div className={'ButtonPad ButtonPadScrolled'}>
          <Link to="/page-2/"><BtnHeader color= "blue">about</BtnHeader></Link>
        </div>
        <a href="mailto:john@johncrossneumann.com"><BtnHeader color= "red">contact</BtnHeader></a>
      </div>
      <div className={'HeaderDivider HeaderDividerScrolled'}></div>
    </div>
    )
  }
}

export default HeaderBack
